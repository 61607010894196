import { render, staticRenderFns } from "./muti.vue?vue&type=template&id=3c3ac969&scoped=true"
import script from "./muti.vue?vue&type=script&lang=js"
export * from "./muti.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3ac969",
  null
  
)

export default component.exports
<template>
  <div>
    <div v-if="currentCreative.media_type == 'image'">
      <div>
        <div>
          <div
            class="adCon"
            v-if="isFbPageAd || edit"
          >
            <div
              class="imgCon"
              v-if="!currentCreative.image"
            >
              <el-button
                size="small"
                @click="selectImg"
                >从创意云选择</el-button
              >
              <!-- <el-upload
                class="upload-demo"
                action="#"
                :http-request="upload_img"
                :show-file-list="false"
                :before-upload="beforeUpload"
              >
                <el-button
                  size="small"
                  type="primary"
                  >点击上传</el-button
                >
                <img-tips></img-tips>
              </el-upload> -->
              <gm-upload-material
                ref="uploadMaterial"
                v-model:fileList="fileList"
                :saveAdFileParams="saveAdFileParam"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
              >
                <el-button
                  size="small"
                  type="primary"
                  >点击上传</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                >
                  <img-tips></img-tips>
                </div>
              </gm-upload-material>
            </div>
            <div
              class="imgCon"
              v-else
            >
              <div class="tools">
                <i class="el-icon-zoom-in"></i>
                <i
                  class="el-icon-delete"
                  @click="deleImg"
                ></i>
                <!-- <el-upload
                  class="upload-demo"
                  action="#"
                  :http-request="upload_img"
                  :show-file-list="false"
                  style="display: inline-block"
                  :before-upload="beforeUpload"
                >
                  <i class="el-icon-upload2"></i>
                </el-upload> -->
                <gm-upload-material
                  
                  ref="uploadMaterial"
                  v-model:fileList="fileList"
                  :saveAdFileParams="saveAdFileParam"
                  :on-success="onSuccess"
                  :before-upload="beforeUpload"
                >
                  <i class="el-icon-upload2"></i>
                </gm-upload-material>
              </div>
              <el-image
                :src="currentCreative.image"
                :preview-src-list="[currentCreative.image]"
                fit="contain"
                @error.once="setImg"
              ></el-image>
            </div>
            <div
              class="imgForm"
              v-if="!isFbPageAd"
            >
              <copywriter
                :creative="currentCreative"
                @setCreativeMsg="setCreativeMsg"
              ></copywriter>
            </div>
          </div>
          <div
            class="imgMsg"
            v-else
          >
            <div
              class="imgCon"
              v-if="imgList.length < canUploadNum"
            >
              <el-button
                size="small"
                @click="selectImg(30)"
                >从创意云选择</el-button
              >
              <!-- <el-upload class="upload-demo" action="#" :http-request="upload_img" :show-file-list="false"
                                :before-upload="beforeUpload" multiple>
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip" style="line-height: 28px">
                                    <img-tips></img-tips>
                                </div>
                            </el-upload> -->
              <gm-upload-material
                ref="uploadMaterial"
                v-model:fileList="fileList"
                :saveAdFileParams="saveAdFileParam"
                :on-success="onSuccess"
                :before-upload="beforeUpload"
              >
                <el-button
                  size="small"
                  type="primary"
                  >点击上传</el-button
                >
                <div
                  slot="tip"
                  class="el-upload__tip"
                >
                  <img-tips></img-tips>
                </div>
              </gm-upload-material>
            </div>
            <div class="imgList">
              <div
                :class="['imgCon', index == activeIndex ? 'active' : '']"
                v-for="(img, index) in imgList"
                :key="img.id"
                @click="changeImg(index)"
              >
                <i
                  class="el-icon-circle-close delete"
                  @click.stop="deleImg(index)"
                ></i>
                <el-image
                  :src="img.url"
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
        <div
          class="imgForm"
          v-if="!(isFbPageAd || edit)"
        >
          <copywriter
            v-if="imgList.length"
            :creative="currentImgMsg"
            @setCreativeMsg="setImgMsg"
          ></copywriter>
          <span
            v-else
            style="color: #f00; font-size: 12px"
            >请先上传素材再填写文案哦~~</span
          >
        </div>
      </div>
    </div>
    <img-list
      :adSeriesInfo="adSeriesInfo"
      :imgListShow="imgListShow"
      :saveAdFileParam="saveAdFileParam"
      @close="imgListShow = false"
      @urlList="urlList"
      uploadTypes="img"
      :limit="limit"
    ></img-list>
  </div>
</template>
<script>
import tools from '../tools';
import adsMethods from '../../adsMethods';
import imgList from '@/views/adManagement/createAd/components/imgList';
import imgTips from './imgTips.vue';
import copywriter from './copywriter.vue';
export default {
  mixins: [tools, adsMethods],
  props: {
    adSeriesInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentCreative: {
      type: Object,
      default: () => {},
    },
    isFbPageAd: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    imgList,
    imgTips,
    copywriter,
  },
  data() {
    return {
      uploadTypes: 'img',
      imgListShow: false,
      activeIndex: 0,
      defaultCopywriter: { copywriter: { title: '', content: '' }, message: '' },
      limit: 1,
    };
  },
  computed: {
    canUploadNum() {
      return 30;
    },
    currentImgMsg() {
      return this.imgList[this.activeIndex]?.copywriter
        ? { copywriter: this.imgList[this.activeIndex].copywriter, message: this.imgList[this.activeIndex].message }
        : { copywriter: { title: '', content: '' }, message: '' } || this.defaultCopywriter;
    },
    imgList: {
      get() {
        return this.currentCreative.imgList || [];
      },
      set(v) {
        this.setCreativeMsg(v);
      },
    },
  },
  methods: {
    // 点击素材库上传
    selectImg(v) {
      this.limit = v || 1;
      if (this.edit || this.isFbPageAd) {
        this.limit = 1;
      }
      this.imgListShow = true;
    },
    // 修改创意
    setCreativeMsg(v) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, ...v });
      } else {
        this.$emit('setCreativeMsg', { ...this.currentCreative, imgList: v });
      }
    },
    // 修改文案
    setImgMsg(v, key) {
      console.log(v, 'this.imgList, this.activeIndex, {...this.imgList[this.activeIndex]');
      this.$set(this.imgList, this.activeIndex, { ...this.imgList[this.activeIndex], ...v });
      // 第一次填写，其他方案默认一致
      // this.imgList.forEach((item, index) => {
      //     console.log(item);
      //     if (index == this.activeIndex) return;
      //     if(key == 'title' || key == 'content'){
      //         if(!item.copywriter){
      //             this.$set(item,'copywriter',{ title: '', content: '' })
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }else{
      //             if(!item.copywriter[key]) item.copywriter[key] =  v.copywriter[key]
      //         }
      //     }else{
      //         if(!item[key]) item[key] = v[key]
      //     }
      // })
    },
    // 获取素材库图片
    urlList(v) {
      if (this.isFbPageAd || this.edit) {
        this.setCreativeMsg({ image: v[v.length - 1].url, source_id: v[v.length - 1].id , mediaSourceType: v[v.length - 1].sourceType});
      } else {
        this.imgList = this.imgList.concat(v).slice(0, 30);
      }
    },
    // 设置文案
    setCopyWriter(index) {
      // let defaultCopywriter = { copywriter: { title: this.defaultTilte, content: this.defaultContent }, message: this.defaultMessage }
      // this.imgList[index] = { ...this.imgList[index], ...defaultCopywriter }
      let data = this.imgList[index];
      if (!data.copywriter) {
        this.$set(data, 'copywriter', {
          title: this.defaultTitle(this.imgList),
          content: this.defaultContent(this.imgList),
        });
      } else {
        if (!data.copywriter['title']) data.copywriter['title'] = this.defaultTitle(this.imgList);
        if (!data.copywriter['content']) data.copywriter['content'] = this.defaultContent(this.imgList);
      }
      if (!data.message) data.message = this.defaultMessage(this.imgList);
    },
    // 点击选择图片切换广告语
    changeImg(index) {
      this.setCopyWriter(index);
      this.activeIndex = index;
      this.$emit('setPreviewIndex', this.activeIndex);
    },
    // 点击删除图片
    deleImg(index) {
      if (this.isFbPageAd || this.edit) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, image: '' });
      } else {
        this.imgList.splice(index, 1);
        this.changeImg(0);
        this.setCreativeMsg(this.imgList);
      }
    },
    // 上传图片
    // upload_img(file) {
    //   this.uploadImg(file).then((data) => {
    //     if (this.edit || this.isFbPageAd) {
    //       this.$emit('setCreativeMsg', { ...this.currentCreative, source_id: data.id, image: data.url });
    //     } else {
    //       this.imgList = this.imgList.concat(data).slice(0, 30);
    //     }
    //   });
    // },
    onSuccess(data) {
      if (this.edit || this.isFbPageAd) {
        this.$emit('setCreativeMsg', { ...this.currentCreative, source_id: data.id, image: data.url,mediaSourceType: data.sourceType });
      } else {
        this.imgList = this.imgList.concat(data).slice(0, 30);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.imgCon {
  width: 220px;
  height: 220px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 15px;
  margin-right: 8px;
  // overflow: hidden;
  position: relative;
  margin: 5px;

  .el-image {
    width: 100%;
    height: 100%;
  }

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 2;
    font-size: 18px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
  }
}

.imgMsg {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .imgList {
    flex: 1;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-y: hidden;

    .imgCon {
      flex-shrink: 0;
      border: 3px solid transparent;
      &.active {
        border-color: #409eff;
      }
    }
  }
}

.imgForm {
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div>
    <p>广告预览</p>
    <!-- {{ currentCreative }} -->
    <el-select v-model="ad_format" placeholder="请选择" v-if="initData.placement">
      <el-option-group v-for="group in initData.placement.PublisherPlatform" :key="group.key" :label="group.desc">
        <el-option v-for="(item, index) in group.positions.filter((v) => v.adFormat)" :key="item.value" :label="item.desc"
          :value="item.adFormat.key + '&' + index">
        </el-option>
      </el-option-group>
    </el-select>
    <div class="iframe" id="iframe" ref="previewFrame" v-loading="previewLoading" v-if="creativeList.length">
      <div class="listBtn">
        <el-button icon="el-icon-arrow-left" type="text" :disabled="index == 0" @click="index-=1"></el-button>
        <span><span class="">{{index + 1}}</span>/ {{ creativeList.length}}</span>
        <el-button icon="el-icon-arrow-right" type="text" :disabled="index == creativeList.length-1"  @click="index+=1"></el-button>
      </div>
      <div v-html="iframeList[index]"></div>
    </div>
  </div>
</template>
<script>
import {
  preview,
} from '@/api/creatAd.js';
import tools from '../tools'
import adsMethods from '../../adsMethods'
let _ = require('lodash');
export default {
  mixins: [tools, adsMethods],
  props: {
    initData: {
      type: Object,
      default: () => { }
    },
    accountId: {
      type: String,
      default: 0,
    },
    adSeriesInfo: {
      type: Object,
      default: () => {
        return { siteUrl: null };
      },
    },
    campaign_objective: {
      type: String,
      default: 'OUTCOME_SALES', //转化量
    },
    rowThirdNum: {
      type: String,
      default: '',
    },
    currentCreative: {
      type: Object,
      default: () => { }
    },
    isFbPageAd:{
      type: Boolean,
      default: false,
    },
    edit:{
      type: Boolean,
      default: false,
    },
    previewIndex:{
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      ad_format: 'MOBILE_FEED_STANDARD&0',
      previewLoading: false,
      iframeList: [],
      index: 0,
    }
  },
  computed: {
    media_type() {
      return this.currentCreative.media_type
    },
    creativeList(){
      if (this.edit || this.isFbPageAd || (this.media_type == 'muti' || this.media_type == 'post' || this.media_type == 'asset_feed')) {
        return [this.currentCreative]
      }else{
        return this.setCreative()
      }
    },
    creative() {
      return this.creativeList[this.index] || {}
    }
  },
  watch: {
    creative: {
      handler(newV, old) {
        console.log(newV, old,'creative');
        
        this.debouncedPreviewIframe(this.index)
      },
      deep: true,
      immediate: true
    },
    previewIndex:{
      handler() {
        this.index = this.previewIndex;
      }
    }
  },
  created(){
    this.debouncedPreviewIframe = _.debounce(this.previewIframe, 1500);
  },
  methods: {
    setCreative(){
      let imgList = this.currentCreative.imgList || [];
      let creative = JSON.parse(JSON.stringify(this.currentCreative));
      delete creative.imgList;
      
      let list = [];
      imgList.forEach(item=>{
        let msg = {copywriter:item.copywriter, message:item.message}
        if(!msg.copywriter){
          msg.copywriter = {
            title:this.defaultTitle(imgList),
            content:this.defaultContent(imgList)
          }
        }else{
          msg.copywriter = {
            title:msg.copywriter.title || this.defaultTitle(imgList),
            content:msg.copywriter.content || this.defaultContent(imgList)
          }
        }
        msg.message = msg.message || this.defaultMessage(imgList)
        list.push({...creative,image: item.url, source_id: item.id,mediaSourceType:item.sourceType || item.mediaSourceType,thumbUrl:item.thumbUrl,...msg,videoStatus:item.videoStatus})
      })
      return list
    },
    previewIframe(index) {
      let creative = JSON.parse(JSON.stringify(this.creative));
      creative.mediaSourceType = creative.sourceType || creative.mediaSourceType;
      delete creative.sourceType;
      const validCreativeObj = this.validCreative(creative, this.adSeriesInfo);
      // console.log(validCreativeObj,'validCreativeObj');
      
      if (validCreativeObj.flag) {
        let ad_format = this.ad_format.split('&')[0];
        let params = {
          account_id: this.accountId,
          ad_format: ad_format,
          objective: this.adSeriesInfo.objective || this.campaign_objective,
          creative: validCreativeObj.creative,
          ...creative.copywriter,
        };
        this.previewLoading = true;
        preview(params).then((res) => {
          this.previewLoading = false;
          this.$set(this.iframeList, index, res.data);
          this.$nextTick(() => {
            if (this.$refs.previewFrame.getElementsByTagName('iframe')[0]) {
              this.$refs.previewFrame.getElementsByTagName('iframe')[0].width = '100%';
            }
          });
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .listBtn{
    font-size: 14px;
    color: #666;

  }
</style>